import React from "react"
import Main from "./components/main";
import "./index.css";
  
function App() {
    
  return (
    <div>
      <Main/>
    </div>
  );
}

export default App